import React, { useState, useEffect } from 'react';
import firebase from 'utils/firebase';

const TableRow = ({
  planSnapshot,
}: {
  planSnapshot: firebase.firestore.QueryDocumentSnapshot;
}) => {
  const [product, setProduct] = useState<
    firebase.firestore.QueryDocumentSnapshot
  >();

  useEffect(() => {
    async function run() {
      const productSnapshot = (await planSnapshot
        .data()
        .products?.[0].get()) as firebase.firestore.QueryDocumentSnapshot;
      setProduct(productSnapshot);
    }
    run();
  }, [planSnapshot]);

  const vidyardId = product?.data?.().vidyardId;

  return (
    <tr>
      <td className="border-1 px-3 py-2">{planSnapshot.data().name}</td>
      <td className="border-1 px-3 py-2">
        {vidyardId && (
          <a
            className="hover:underline"
            href={`https://share.vidyard.com/watch/${vidyardId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            https://share.vidyard.com/watch/{vidyardId}
          </a>
        )}
      </td>
    </tr>
  );
};

export default TableRow;
