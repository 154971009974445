import React from 'react';
import Layout from 'components/layout';
import SEO from 'components/seo';
import { Router } from '@reach/router';
import {
  Video,
  Dashboard,
  Invitation,
  Login,
  Logout,
  Purchase,
  Trailer,
  Rules,
  PaymentSuccess,
} from 'views';
import Route from 'components/route';
import { Role } from 'utils/auth';
import useLogRocket from 'hooks/useLogRocket';
import MoviesDashboard from 'views/movies-dashboard';

const IndexPage = () => {
  useLogRocket();
  return (
    <Layout>
      <SEO title="Home" />
      <Router basepath="/app">
        <Route
          path="recommend"
          component={() => <Trailer withPurchaseOption />}
        />
        <Route path="rules" component={Rules} />
        <Route path="purchase" component={Purchase} />
        <Route
          path="video"
          component={Video}
          allowedRoles={[Role.PARENT, Role.MANAGER, Role.ADMIN]}
        />
        <Route path="login" component={Login} />
        <Route
          path="dashboard"
          component={Dashboard}
          allowedRoles={[Role.MANAGER, Role.ADMIN]}
        />
        <Route
          path="dashboard/movies"
          component={MoviesDashboard}
          allowedRoles={[Role.MANAGER, Role.ADMIN]}
        />
        <Route<{ institutionId: string }>
          path="invitation/:institutionId"
          component={({ institutionId }) => (
            <Invitation institutionId={institutionId!} />
          )}
        />
        <Route path="payment-success" component={PaymentSuccess} />
        <Route path="logout" component={Logout} />
      </Router>
    </Layout>
  );
};

export default IndexPage;
