import React, { useState, useEffect } from 'react';
import firebase from 'utils/firebase';
import NavBar from 'components/navbar';
import NewMovieForm from './new-movies-form';
import TableRow from './table-row';

const MoviesDashboard = () => {
  const [plans, setPlans] = useState<
    firebase.firestore.QueryDocumentSnapshot[]
  >();

  useEffect(() => {
    firebase
      .firestore()
      .collection('plans')
      .orderBy('name')
      .onSnapshot((querySnapshot) => {
        setPlans(querySnapshot.docs);
      });
  }, []);

  function handleSubmit({ name, vidyardId }) {
    const type = 'video';
    const price = 990;
    const productsRef = firebase.firestore().collection('products');
    const plansRef = firebase.firestore().collection('plans');
    firebase.firestore().runTransaction(async () => {
      const productRef = await productsRef.add({ type, vidyardId });
      await plansRef.add({ name, price, products: [productRef] });
    });
  }

  return (
    <div className="container px-3">
      <div className="pb-8 pt-2 text-sm">
        <NavBar className="mb-6" withDashboardNaigation withLogout />
        <NewMovieForm onSubmit={handleSubmit} />
      </div>
      <h1 className="text-xl mt-6 mb-4">Lista filmów</h1>
      <table className="table-auto w-full mb-8 text-sm">
        <thead>
          <tr className="font-bold">
            <td className="border-1 px-3 py-2">Nazwa filmu</td>
            <td className="border-1 px-3 py-2">Podgląd filmu</td>
          </tr>
        </thead>
        <tbody>
          {plans &&
            plans.map((planSnapshot) => (
              <TableRow key={planSnapshot.id} planSnapshot={planSnapshot} />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default MoviesDashboard;
