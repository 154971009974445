import React, { FunctionComponent } from 'react';
import { Form, Field } from 'react-final-form';
import Button from 'components/button';
import { required } from 'utils/validators';
import FieldError from 'components/forms/field-error';

type Props = {
  onSubmit: (values: { name: string; vidyardId: string }) => void;
};

const NewInstitutionForm: FunctionComponent<Props> = ({ onSubmit }) => {
  return (
    <Form
      // @ts-ignore
      onSubmit={async ({ name, vidyardId }, { restart }) => {
        await onSubmit({ name, vidyardId });
        setTimeout(() => restart());
      }}
    >
      {({ submitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <label className="block">
            Nazwa filmu:
            <Field
              name="name"
              type="text"
              component="input"
              validate={required()}
              required
              className="border-1 border-gray-300 border-solid m-2"
            />
          </label>
          <FieldError name="name" />
          <label className="block">
            Vidyard id:
            <Field
              name="vidyardId"
              type="text"
              component="input"
              validate={required()}
              required
              className="border-1 border-gray-300 border-solid m-2"
            />
          </label>
          <FieldError name="vidyardId" />
          <Button
            type="submit"
            variant="success"
            className="mt-1"
            disabled={submitting}
            isLoading={submitting}
          >
            Dodaj film
          </Button>
        </form>
      )}
    </Form>
  );
};

export default NewInstitutionForm;
